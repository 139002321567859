<template>
  <b-row>
    <b-col md="6">
      <b-card>
        <h3 class="text-center">
          {{ $t("others.class_students") }}
        </h3>
        <div class="d-flex justify-content-between align-items-center">
          <b-form-group :label="$t('general.search')" label-for="search">
            <b-input-group :label="$t('general.search')" label-for="search">
              <b-input-group-prepend is-text class="border-right-0">
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="search"
                v-model="params.name"
                :placeholder="$t('general.search') + '...'"
                class="border-left-0 pl-0"
                @input="filter"
              />
            </b-input-group>
          </b-form-group>
          <b-button
            v-if="
              $can('manage', 'admin') ||
              $can('manage', 'super_admin') ||
              $can('manage', 'head_manager') ||
              $can('manage', 'id_manager')
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="$router.push(`/class/class-info/${GET_ONE_CLASS.id}`)"
          >
            {{ $t("general.edit") }}
            <!--Добавить button-->
          </b-button>
        </div>
        <b-row>
          <b-col cols="12" class="mt-2">
            <b-table
              hover
              show-empty
              responsive
              :items="GET_CLASS_STUDENTS.results"
              :fields="fields"
              :empty-text="$t('general.no_data_to_view')"
              class="mb-0"
              @row-clicked="
                ({ user_id }) => $router.push(`/users/${user_id}/info`)
              "
            >
              <template #head()="{ label }">
                {{ $t(label) }}
              </template>
              <template #cell(phone_number)="data">
                <span class="text-nowrap">
                  {{ data.value }}
                </span>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <!-- pagination -->
        <!-- slot-scope="props" -->
        <template>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">{{ $t("general.show_from") }}</span>
              <b-form-select
                v-model="params.page_size"
                :options="pageOptions"
                class="mx-1"
                @change="changePerPage"
              />
              <span class="text-nowrap"> {{ $t("general.show_to") }} </span>
              <!-- из {{ props.total }} -->
            </div>
            <div>
              <b-pagination
                v-model="params.page"
                :total-rows="GET_CLASS_STUDENTS.count"
                :per-page="params.page_size"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </b-card>
    </b-col>
    <b-col md="6">
      <ClassDetailCourse />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BFormSelect,
  BPagination,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { required } from "@validations";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Ripple from "vue-ripple-directive";
import areYouSure from "@/mixins/areYouSure";
import toast from "@/mixins/toast";
import ClassDetailCourse from "./ClassDetailCourse.vue";

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BFormSelect,
    BPagination,
    BFormGroup,
    BFormInput,
    BButton,
    ClassDetailCourse,
    BInputGroup,
    BInputGroupPrepend,
  },
  directives: {
    Ripple,
  },
  mixins: [areYouSure, toast],
  data() {
    return {
      pageOptions: [3, 5, 10],
      params: {
        id: this.$route.params.id,
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
      required,
      fields: [
        {
          label: "users.full_name",
          key: "full_name",
        },
        {
          label: "Email",
          key: "email",
        },
        {
          label: "users.phone_number",
          key: "phone",
        },
        {
          label: "users.level",
          key: "level",
        },
      ],
      defStudents: [],
      addStudent: false,
    };
  },
  computed: {
    ...mapGetters("classes", ["GET_ONE_CLASS", "GET_CLASS_STUDENTS"]),
    ...mapGetters("courses", ["GET_CLASS_COURSE"]),
  },
  mounted() {
    this.FETCH_CLASS(this.$route.params.id).then(() => {
      this.updateBreadcrumb();
      this.defStudents = this.GET_ONE_CLASS.students;
    });
    this.FETCH_CLASS_STUDENTS(this.params).catch(() => {
      this.$router.go(-1);
      this.$_errorToast("Ошибка подключении");
    });
    this.FETCH_CLASS_COURSE({ group: this.$route.params.id });
  },
  methods: {
    ...mapActions("classes", [
      "FETCH_CLASS",
      "CREATE_CLASS",
      "UPDATE_CLASS",
      "FETCH_CLASS_STUDENTS",
    ]),
    ...mapActions("courses", ["FETCH_CLASS_COURSE", "DELETE_CLASS_COURSE"]),
    ...mapMutations("breadcrumb", ["UPDATE_BREADCRUMB"]),

    updateBreadcrumb() {
      const [first, second] = this.$_removeObjectConnection(
        this.$route.meta.breadcrumb
      );
      second.text = this.GET_ONE_CLASS.title;
      this.UPDATE_BREADCRUMB([first, second]);
    },
    // for pagination and filters
    changePerPage(page_size) {
      this.params.page_size = page_size;
      this.params.page = 1;
      this.FETCH_CLASS_STUDENTS(this.params);
    },
    changePage(value) {
      this.params.page = value;
      this.FETCH_CLASS_STUDENTS(this.params);
    },
    filter() {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.FETCH_CLASS_STUDENTS(this.params);
      }, 600);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<template>
  <b-card>
    <h3 class="text-center">
      {{ $t('users.courses') }}
    </h3>
    <div class="d-flex justify-content-end align-items-center">
      <!-- <b-form-group
        :label="$t('Поиск')"
        label-for="search"
      >
        <b-input-group
          :label="$t('Поиск')"
          label-for="search"
        >
          <b-input-group-prepend
            is-text
            class="border-right-0"
          >
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="search"
            v-model="params.search"
            :placeholder="$t('Поиск') + '...'"
            class="border-left-0 pl-0"
            @input="filter"
          />
        </b-input-group>
      </b-form-group> -->
      <b-button
        v-if="$can('manage', 'admin') || $can('manage', 'super_admin') || $can('manage', 'head_manager') || $can('manage', 'id_manager')"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        @click="$router.push(`/class/class-predmets/${GET_ONE_CLASS.id}`)"
      >
        {{ $t('general.edit') }}
        <!--Добавить button-->
      </b-button>
    </div>
    <b-row>
      <b-col
        cols="12"
        class="mt-2"
      >
        <b-table
          hover
          show-empty
          responsive
          :items="GET_CLASS_COURSE.results"
          :fields="fields2"
          :empty-text="$t('general.no_data_to_view')"
          class="mb-0"
        >
          <template #head()="{ label}">
            {{ $t(label) }}
          </template>
          <template #cell(acces)="data">
            <b-form-checkbox
              :checked="data.value"
              disabled
              inline
            />
          </template>
          <template #cell(course)="data">
            {{ data.value.title }}
          </template>
          <template #cell(teachers)="data">
            <p
              v-for="item in data.value"
              :key="item.id"
            >
              {{ item.full_name }}
            </p>
          </template>
          <template #cell(access)="{ item }">
            {{ $_dateTimeFormatter(item.access_start_date).split(',')[0] }} - {{ $_dateTimeFormatter(item.access_end_date).split(',')[0] }}
          </template>

          <template #cell(is_active)="data">
            <b-form-checkbox
              :checked="data.value"
              name="check-button"
              switch
              inline
              disabled
            />
          </template>
          <template
            #cell(actions)="data"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon p-25"
              size="sm"
              @click="deleteCourse(data.item.id)"
            >
              <feather-icon
                size="22"
                icon="XIcon"
              />
            </b-button>
            <!-- </div> -->
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BButton,
  BFormCheckbox,
  // BFormGroup,
  // BFormInput,
  // BInputGroup,
  // BInputGroupPrepend,
} from 'bootstrap-vue'
import { required } from '@validations'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import areYouSure from '@/mixins/areYouSure'
import toast from '@/mixins/toast'
import extra from '@/mixins/extra'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BFormCheckbox,
    BButton,
    // BFormGroup,
    // BFormInput,
    // BInputGroup,
    // BInputGroupPrepend,
  },
  directives: {
    Ripple,
  },
  mixins: [areYouSure, toast, extra],
  data() {
    return {
      pageOptions: [3, 5, 10],
      params: {
        id: this.$route.params.id,
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
      required,
      fields2: [
        {
          label: 'classes.course_name',
          key: 'course',
        },
        {
          label: 'users.teachers',
          key: 'teachers',
        },
        {
          label: 'general.actions',
          key: 'access',
        },
      ],
      defStudents: [],
      addStudent: false,
    }
  },
  computed: {
    ...mapGetters('classes', ['GET_ONE_CLASS', 'GET_CLASS_STUDENTS']),
    ...mapGetters('courses', ['GET_CLASS_COURSE']),
  },
  methods: {
    ...mapActions('classes', ['FETCH_CLASS', 'CREATE_CLASS', 'UPDATE_CLASS', 'FETCH_CLASS_STUDENTS']),
    ...mapActions('courses', [
      'FETCH_CLASS_COURSE',
      'DELETE_CLASS_COURSE',
    ]),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),

    updateBreadcrumb() {
      const [first, second] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      second.text = this.GET_ONE_CLASS.title
      this.UPDATE_BREADCRUMB([first, second])
    },
    // for pagination and filters
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      this.FETCH_CLASS_STUDENTS(this.params)
    },
    changePage(value) {
      this.params.page = value
      this.FETCH_CLASS_STUDENTS(this.params)
    },
    filter(filteredData) {
      this.params.name = filteredData.search
      this.FETCH_CLASS_STUDENTS(this.params)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

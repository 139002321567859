var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-card',[_c('h3',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("others.class_students"))+" ")]),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('b-form-group',{attrs:{"label":_vm.$t('general.search'),"label-for":"search"}},[_c('b-input-group',{attrs:{"label":_vm.$t('general.search'),"label-for":"search"}},[_c('b-input-group-prepend',{staticClass:"border-right-0",attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1),_c('b-form-input',{staticClass:"border-left-0 pl-0",attrs:{"id":"search","placeholder":_vm.$t('general.search') + '...'},on:{"input":_vm.filter},model:{value:(_vm.params.name),callback:function ($$v) {_vm.$set(_vm.params, "name", $$v)},expression:"params.name"}})],1)],1),(
            _vm.$can('manage', 'admin') ||
            _vm.$can('manage', 'super_admin') ||
            _vm.$can('manage', 'head_manager') ||
            _vm.$can('manage', 'id_manager')
          )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"success"},on:{"click":function($event){return _vm.$router.push(("/class/class-info/" + (_vm.GET_ONE_CLASS.id)))}}},[_vm._v(" "+_vm._s(_vm.$t("general.edit"))+" ")]):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('b-table',{staticClass:"mb-0",attrs:{"hover":"","show-empty":"","responsive":"","items":_vm.GET_CLASS_STUDENTS.results,"fields":_vm.fields,"empty-text":_vm.$t('general.no_data_to_view')},on:{"row-clicked":function (ref) {
                    var user_id = ref.user_id;

                    return _vm.$router.push(("/users/" + user_id + "/info"));
}},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
          var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(phone_number)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.value)+" ")])]}}])})],1)],1),[_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t("general.show_from")))]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":_vm.pageOptions},on:{"change":_vm.changePerPage},model:{value:(_vm.params.page_size),callback:function ($$v) {_vm.$set(_vm.params, "page_size", $$v)},expression:"params.page_size"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("general.show_to"))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"total-rows":_vm.GET_CLASS_STUDENTS.count,"per-page":_vm.params.page_size,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"change":_vm.changePage},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}})],1)])]],2)],1),_c('b-col',{attrs:{"md":"6"}},[_c('ClassDetailCourse')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }